import React from 'react'
import { Link } from 'gatsby'

const Tag = props => {
  return (
    <span className="tag">
      <Link to={`/my-logue/tag/${props.tag.slug}`}>{props.tag.tag}</Link>
    </span>
  )
}
export default Tag
